import React from 'react';
import Label from "./Label";

/**
 * Callout component.
 * 
 * @param heading: bold short text to display next to icon. optional.
 * @param message: text to display.
 * @param showIcon: flag to show icon or not. optional. defaults to true.
 * @param showLabel: flag to show label or not. optional. defaults to false.
 * @param labelText: label text to display.
 */
type Props = {
    heading?: string,
    message: string,
    showIcon?: boolean,
    showLabel?: boolean,
    labelText?: string
};

function Callout({heading, message,showIcon=true, showLabel=false, labelText=""} : Props) {
    return (
        <>
            {showLabel && <Label id="" label={labelText} className="alt-required" />}
            <section className="qg-callout__box qg-callout__grey-theme" style={{marginTop: showLabel ? 0 : 25, marginBottom: showLabel ? 0 : 25}}>
                { heading ?
                    <div className="qg-callout__header col-12">
                        {showIcon ? <span className="qg-callout__icon"><span className="fa fa-comments"></span></span> : null }
                        <h2 className="qg-callout__title">{heading}</h2>
                    </div>
                : null }

                <div className="qg-callout__content col-12">
                    <div className="qg-callout__bottom-alignment">
                    <div className="qg-callout__description">
                        <p>{message}</p>
                    </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Callout;