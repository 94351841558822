import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { extractIpn, scrollToTopOptions } from "../utils/helpers";
import SuccessAlert from "../components/swe/success-alert";
import { useLocation, useNavigate } from "react-router-dom";
import InfoAlert from "../components/swe/info-alert";
import Loading from "../components/Loading";
import PrimaryButton from "../components/swe/primary-button";
import SecondaryButton from "../components/swe/secondary-button";
import WarningAlert from "../components/swe/warning-alert";
import useGlobalState from "../hooks/useGlobalState";
import * as Constants from "../constants";
import LabelRadio from "../components/swe/base/label-radio";

export const LegalFirstNameSelection = () => {
    const { isAuthenticated, user, getAccessTokenSilently, getIdTokenClaims } = useAuth0();
    const { globalState, saveGlobalState } = useGlobalState();
    const navigate = useNavigate();
    const { state } = useLocation();
    const navigatedFullNameIndex = state?.selectedFullNameRecordIndex;
    const navigatedNameOptions = state?.nameOptions;
    const navigatedNameOptionIndex = state?.selectedNameOptionIndex;

    const [nameOptions, setNameOptions] = useState([]);
    const [nameOptionIndex, setNameOptionIndex] = useState(-1);
    const [fullnameOptionIndex, setFullNameOptionIndex] = useState(-1);
    const [isLoading, setIsLoading] = useState(true);
    const [skipped, setSkipped] = useState(false);
    const [backCount, setBackCount] = useState(0);
    const [isUserIP1P, setIsUserIP1P] = useState(false);
    const [optionsList, setOptionsList] = useState(new Map<string, string>);

    useEffect(() => {
        (async () => {

            try {
                if (!isAuthenticated) {
                    // Throw exception if user not authenticated
                    throw new Error("User is not authenticated!");
                }
                await getAccessTokenSilently({ ignoreCache: true });
                const claims = await getIdTokenClaims();
                const ipn = extractIpn(claims!['http://oauth.tmr.qld.gov.au'].acr);

                if (Constants.DEBUG) {
                    console.log('user claims  ipn ->', ipn);
                    console.log('%c[LegalNameSelection] isAuthenticated: %o; user: %o\nipn:%o\nacr:%o\nstate:%o\nglobalState:%o', 'color: #9c6;',
                        isAuthenticated, user, globalState.sessionToken?.ipn, globalState.sessionToken?.acr_values, globalState.idState, globalState);
                }

                saveGlobalState({ sessionToken: JSON.parse(localStorage.getItem('session_token')!) });
                if (globalState.sessionToken.ipn !== "ip1p_nc" && ipn) globalState.sessionToken.ipn = ipn;

                // Get users current IP value and check if IP1P. This is used to decide
                // whether to display the 'provide another document' link currently only
                // and option for users requesting IP1P
                setIsUserIP1P(globalState.sessionToken?.ipn === "ip1p" ? true : false);

                // Check if user should be here
                let mfaAvailable = globalState.userCompletedMFA; 
                if (typeof mfaAvailable === 'undefined' || (user && user.email_verified === false)) {
                    if (Constants.DEBUG) { console.log('%c%s', 'color: #c00;', "Step blocked. Redirecting to uplift"); }
                    navigate("/ip-uplift", { replace: true });
                }
            } catch (error) {
                navigate("/unauthorised", { replace: true });
            }

        })();
    }, []); 

    useEffect(() => {
        // Set the name options sent from the previous page
        if(navigatedNameOptions) {
            setNameOptions(navigatedNameOptions);
        }       

        // Get the back count from session storage and set it to state. If not available set it to 0.
        const backCountInSession = window.sessionStorage.getItem("ClaimNameBackCount");
        if (backCountInSession) {
            setBackCount(+backCountInSession);
        } else {
            window.sessionStorage.setItem("ClaimNameBackCount", '0');
        }
    }, []);

    useEffect(() => {
        // Set Full Name Index and Name Option Index if they are available in the state
        if ((navigatedFullNameIndex ?? -1) > -1) {
            setFullNameOptionIndex(navigatedFullNameIndex);
        }
        if ((navigatedNameOptionIndex ?? -1) > -1) {
            setNameOptionIndex(navigatedNameOptionIndex);
        }
    }, [])

    useEffect(() => {
        // Set the options list for the radio buttons
        if (nameOptions && nameOptions.length > 0) {
            
            let options = new Map<string, string>();
            nameOptions[navigatedFullNameIndex]?.options.map((v: any,i: string) => {
                options.set(i,v.givenName);
            });
            setOptionsList(options);
            setIsLoading(false);
        }
    }, [nameOptions]);
  
    useEffect(() => {
      window.scrollTo(scrollToTopOptions);
    }, [])

    useEffect(() => {
        // Remove the skipped flag if the user selects a name option which will hide the warning alert
        if ((nameOptionIndex ?? -1) > -1) {
            setSkipped(false);
        }
    }, [nameOptionIndex])

    const selectAnotherDocument = () => {
        // Set a session storage flag to indicate that the user is adding a document for name claim
        // and navigate to verify your identity page
        window.sessionStorage.setItem('addDocForNameClaim', 'true');
        window.sessionStorage.setItem("ClaimNameBackCount", '0');
        navigate("/ip-uplift/verify-your-identity", { replace: true, state: { ipnLevel: "ip1p", navigateBackTo: "/ip-uplift/legal-first-name-selection" } });
    }

    const skipClicked = () => {
        if(skipped) {
            const sessionToken = localStorage.getItem('session_token');
            saveGlobalState({ sessionToken: JSON.parse(sessionToken == null ? "" : sessionToken) });
            navigate("/return-to-relying-party", { replace: true });
        }
        setSkipped(true);
        setNameOptionIndex(-1);
    }

    const continueClicked = () => {
        // Redirect to the LegalNameConfirmation page with the selected name option
        navigate("/ip-uplift/legal-name-confirmation", { 
            replace: true, 
            state: { 
                nameOptions: nameOptions,  
                selectedFullNameRecordIndex: fullnameOptionIndex,
                selectedNameOptionIndex: nameOptionIndex, 
                backNavigation: "/ip-uplift/legal-first-name-selection",
            } 
        });
    }

    const backClicked = () => {
        // Check if the user has attempted to go back more than the allowed attempts
        if(backCount >= +Constants.ALLOWED_CLAIM_NAME_BACK_ATTEMPTS!) {
            navigate("/ip-uplift/legal-name-too-many-attempts", { replace: true });
        } else {
            // Increment the back count and navigate back to LegalFullNameSelection page
            window.sessionStorage.setItem("ClaimNameBackCount", (backCount+1).toString());
            navigate("/ip-uplift/legal-full-name-selection", { 
                replace: true, 
                state: { 
                    nameOptions: nameOptions,  
                    selectedFullNameRecordIndex: fullnameOptionIndex
                } 
            });            
        }
    }

    return (
        <>
            {isAuthenticated &&
                <>
                    {isLoading ? 
                        <Loading /> :
                        <>
                            <h1>Confirm your legal name</h1>

                            {window.sessionStorage.getItem('verifiedCredentialInCurrentSession') === 'true' && (nameOptions.length === 1) &&
                                // Display the success alert if the user has verified their credential within the same session
                                <SuccessAlert 
                                    heading="" 
                                    message="You have successfully verified your credential. You will now need to confirm your legal name." 
                                />
                            }

                            {nameOptions.length > 1 && fullnameOptionIndex > -1 && 
                                // Display the success alert if the user has selected a full name from the previous page
                                <SuccessAlert message={"You have selected your full legal name."} />
                            }

                            <p>
                                We need to confirm your current legal name, as it will be provided 
                                to the agency or service you are accessing.
                            </p>
                            <p>
                                In the next step we will show you how your full name will be saved 
                                in your Queensland Digital Identity.
                            </p>

                            <form className="qg-forms-v2">
                                <ol className="questions">
                                    <li>
                                        <LabelRadio 
                                            id="first-name-options"
                                            label="What is your current legal first name/s?"
                                            inputValue={nameOptionIndex}
                                            setInputValue={setNameOptionIndex}
                                            options={optionsList}
                                            mandatory={true}
                                        />
                                    </li>
                                </ol>
                            </form>

                            {isUserIP1P &&
                                // Display the info alert with the link to provide another document if the user is IP1P
                                <InfoAlert message={
                                    <p>
                                        If you want to use another name, other than the ones listed above, you will need 
                                        to <a onClick={selectAnotherDocument} style={{ cursor:"pointer" }}>provide another document</a> that includes your current legal name.
                                    </p>
                                }/>
                            }

                            {skipped && 
                                <WarningAlert
                                    heading="Sure you want to skip?"
                                    message={<>
                                        <p>
                                            if you select 'Skip', the agency or service you are using will be provided with your name 
                                            as it appears on the first document you verified.
                                        </p>
                                        <p>You will be asked to confirm your current legal name the next time you log in to QDI.</p>
                                        <p>Select 'Skip' if you still wish to skip this step.</p>
                                    </>}
                                />
                            }
                    
                            <ol className="questions">
                                <li className="footer">
                                        {nameOptions.length === 1 ? 
                                            // If there is only one name option, show the continue and skip buttons
                                            <ul className="actions">
                                                <PrimaryButton 
                                                    id="continueButton" 
                                                    heading="Continue" 
                                                    action={continueClicked}
                                                    disabled={nameOptionIndex < 0} 
                                                />
                                                <SecondaryButton 
                                                    id="skipButton" 
                                                    heading="Skip"
                                                    action={skipClicked} 
                                                />    
                                            </ul>
                                            :  
                                            // If there are multiple name options, show the continue and back buttons
                                            <ul className="actions">
                                                <PrimaryButton 
                                                    id="continueButton" 
                                                    heading="Continue" 
                                                    action={continueClicked}
                                                    disabled={nameOptionIndex < 0} 
                                                />
                                                <SecondaryButton 
                                                    id="backButton" 
                                                    heading="Back" 
                                                    action={backClicked} 
                                                /> 
                                            </ul>
                                        }       
                                </li>
                            </ol>
                        </>
                    }
                </>
            }
        </>
    );

}

export default LegalFirstNameSelection;