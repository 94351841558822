import React, { useEffect, useState } from "react";
import { confirmSelectedNameOption, getNameOptions } from "../services/integration-api";
import { useAuth0 } from "@auth0/auth0-react";
import { extractIpn, scrollToTopOptions } from "../utils/helpers";
import SuccessAlert from "../components/swe/success-alert";
import { useLocation, useNavigate } from "react-router-dom";
import Callout from "../components/swe/base/callout";
import InfoAlert from "../components/swe/info-alert";
import SecondaryButton from "../components/swe/secondary-button";
import PrimaryButton from "../components/swe/primary-button";
import WarningAlert from "../components/swe/warning-alert";
import { AssociateQDIUserAccountBuilder } from "../services/x-api";
import useGlobalState from "../hooks/useGlobalState";
import * as Constants from "../constants";

export const LegalNameConfirmation = () => {
    const { isAuthenticated, user, getAccessTokenSilently, getIdTokenClaims } = useAuth0();
    const { globalState, saveGlobalState } = useGlobalState();
    const navigate = useNavigate();

    const { state } = useLocation();
    const selectedFullnameOptionIndex = state?.selectedFullNameRecordIndex;
    const nameOptions = state?.nameOptions;
    const nameRecord = nameOptions[selectedFullnameOptionIndex];
    const nameOptionIndex = state?.selectedNameOptionIndex;
    const backNavigation = state?.backNavigation;
    
    const [skipped, setSkipped] = useState(false);
    const [backCount, SetBackCount] = useState(0);
    const [isUserIP1P, setIsUserIP1P] = useState(false);

    useEffect(() => {
        (async () => {

            try {
                if (!isAuthenticated) {
                    // Throw exception if user not authenticated
                    throw new Error("User is not authenticated!");
                }
                await getAccessTokenSilently({ ignoreCache: true });
                const claims = await getIdTokenClaims();
                const ipn = extractIpn(claims!['http://oauth.tmr.qld.gov.au'].acr);

                if (Constants.DEBUG) {
                    console.log('user claims  ipn ->', ipn);
                    console.log('%c[LegalNameSelection] isAuthenticated: %o; user: %o\nipn:%o\nacr:%o\nstate:%o\nglobalState:%o', 'color: #9c6;',
                        isAuthenticated, user, globalState.sessionToken?.ipn, globalState.sessionToken?.acr_values, globalState.idState, globalState);
                }

                saveGlobalState({ sessionToken: JSON.parse(localStorage.getItem('session_token')!) });
                if (globalState.sessionToken.ipn !== "ip1p_nc" && ipn) globalState.sessionToken.ipn = ipn;

                // Get users current IP value and check if IP1P. This is used to decide
                // whether to display the 'provide another document' link currently only
                // and option for users requesting IP1P
                setIsUserIP1P(globalState.sessionToken?.ipn === "ip1p" ? true : false);

                // Check if user should be here
                let mfaAvailable = globalState.userCompletedMFA; 
                if (typeof mfaAvailable === 'undefined' || (user && user.email_verified === false)) {
                    if (Constants.DEBUG) { console.log('%c%s', 'color: #c00;', "Step blocked. Redirecting to uplift"); }
                    navigate("/ip-uplift", { replace: true });
                }
            } catch (error) {
                navigate("/unauthorised", { replace: true });
            }

        })();
    }, []); 
  
    useEffect(() => {
      window.scrollTo(scrollToTopOptions); 

    // Check if the user has attempted to go back more than the allowed attempts
      const backCountinSession = window.sessionStorage.getItem("ClaimNameBackCount");
      if (backCountinSession) {
          SetBackCount(+backCountinSession);
      } else {
          window.sessionStorage.setItem("ClaimNameBackCount", '0');
      }
    }, []);

    const selectAnotherDocument = () => {
        // Set a session storage flag to indicate that the user is adding a document for name claim
        // and navigate to verify your identity page
        window.sessionStorage.setItem('addDocForNameClaim', 'true');
        window.sessionStorage.setItem("ClaimNameBackCount", '0');
        navigate("/ip-uplift/verify-your-identity", { replace: true, state: { ipnLevel: "ip1p", navigateBackTo: "/ip-uplift/legal-full-name-selection"} });
    }

    const skipClicked = () => {
        if(skipped) {
            const sessionToken = localStorage.getItem('session_token');
            saveGlobalState({ sessionToken: JSON.parse(sessionToken == null ? "" : sessionToken) });
            navigate("/return-to-relying-party", { replace: true });
        }
        setSkipped(true);
    }

    const backClicked = () => {
        // Check if the user has attempted to go back more than the allowed attempts
        if(backCount >= +Constants.ALLOWED_CLAIM_NAME_BACK_ATTEMPTS!) {
            navigate("/ip-uplift/legal-name-too-many-attempts", { replace: true });
        } else {
            // Increment the back count and navigate back to the previous page
            window.sessionStorage.setItem("ClaimNameBackCount", (backCount+1).toString());
            navigate(backNavigation, { 
                replace: true, 
                state: { 
                    nameOptions: nameOptions, 
                    selectedFullNameRecordIndex: selectedFullnameOptionIndex, 
                    selectedNameOptionIndex: nameOptionIndex 
                }
            });
        }
    }

    const confirmClicked = async () => {
        const token = await getAccessTokenSilently({ ignoreCache: true });
        const idToken = await getIdTokenClaims();

        let builder = new AssociateQDIUserAccountBuilder()
            .withFamilyName(nameRecord?.familyName)
            .withGivenName(nameRecord?.options[nameOptionIndex]?.givenName)
            .withMiddleName(nameRecord?.options[nameOptionIndex]?.middleName)
        let envelope = builder.build();

        // Call the API to confirm the selected name option
        const updated = await confirmSelectedNameOption(idToken!.sub, envelope, token);

        //if success redirect to IpUplift
        if (updated) {
            window.sessionStorage.setItem('claimNameRequired', 'false');
            
            const sessionToken = localStorage.getItem('session_token');
            saveGlobalState({ sessionToken: JSON.parse(sessionToken == null ? "" : sessionToken) });
            
            navigate("/ip-uplift", { replace: true });
        }
    }

    return (
        <>
            {isAuthenticated &&
                <>
                    <h1>Confirm your legal name</h1>

                    {window.sessionStorage.getItem('verifiedCredentialInCurrentSession') === 'true' && backNavigation === "" &&
                        <SuccessAlert 
                            heading="" 
                            message="You have successfully verified your credential. You will now need to confirm your legal name." 
                        />
                    }

                    <p>
                        We need to confirm your current legal name, as it will be provided to the agency or service you are accessing.
                    </p>

                    <form className="qg-forms-v2">
                        <ol className="questions">
                            <li>
                                <h4>Name to be saved</h4>
                            </li>
                            <li>
                                <Callout 
                                    showLabel={true}
                                    labelText="First name/s"
                                    message={nameRecord?.options[nameOptionIndex]?.givenName} 
                                />
                            </li>
                            <li>
                                <Callout 
                                    showLabel={true}
                                    labelText="Middle name/s"
                                    message={nameRecord?.options[nameOptionIndex]?.middleName} 
                                />
                            </li>
                            <li>
                                <Callout 
                                    showLabel={true}
                                    labelText="Family name"
                                    message={nameRecord?.familyName} 
                                />
                            </li>
                        </ol>
                    </form>

                    {isUserIP1P &&
                        <InfoAlert message={
                                <p>
                                    If you want to use another name, other than the ones listed above, you will need 
                                    to <a onClick={selectAnotherDocument} style={{ cursor:"pointer" }}>provide another document</a> that includes your current legal name.
                                </p>
                            }
                        />
                    }

                    {skipped && 
                        <WarningAlert
                            heading="Sure you want to skip?"
                            message={<>
                                <p>
                                    if you select 'Skip', the agency or service you are using will be provided with your name 
                                    as it appears on the first document you verified.
                                </p>
                                <p>You will be asked to confirm your current legal name the next time you log in to QDI.</p>
                                <p>Select 'Skip' if you still wish to skip this step.</p>
                            </>}
                        />
                    }
                    
                    <ol className="questions">
                        <li className="footer">
                            <ul className="actions">
                                <PrimaryButton id="confirmButton" heading="Confirm" action={confirmClicked} />
                                {backNavigation === "" ?
                                    <SecondaryButton id="skipButton" heading="Skip" action={skipClicked} /> :
                                    <SecondaryButton id="backButton" heading="Back" action={backClicked} />
                                }
                            </ul>
                        </li>
                    </ol>
                </>
            }
        </>
    );

}

export default LegalNameConfirmation;