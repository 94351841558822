import React, { useEffect, useState } from "react";
import { getNameOptions } from "../services/integration-api";
import { useAuth0 } from "@auth0/auth0-react";
import { extractIpn, scrollToTopOptions } from "../utils/helpers";
import SuccessAlert from "../components/swe/success-alert";
import { useLocation, useNavigate } from "react-router-dom";
import InfoAlert from "../components/swe/info-alert";
import Loading from "../components/Loading";
import PrimaryButton from "../components/swe/primary-button";
import SecondaryButton from "../components/swe/secondary-button";
import WarningAlert from "../components/swe/warning-alert";
import useGlobalState from "../hooks/useGlobalState";
import * as Constants from "../constants";
import LabelRadio from "../components/swe/base/label-radio";

export const LegalFullNameSelection = () => {
    const { isAuthenticated, user, getAccessTokenSilently, getIdTokenClaims } = useAuth0();
    const { globalState, saveGlobalState } = useGlobalState();
    const navigate = useNavigate();
    const { state } = useLocation();
    const navigatedNameOptions = state?.nameOptions;
    const navigatedFullNameIndex = state?.selectedFullNameRecordIndex;

    const [nameOptions, setNameOptions] = useState([]);
    const [nameOptionsList, setNameOptionsList] = useState(new Map<string, string>);
    const [fullnameOptionIndex, setFullNameOptionIndex] = useState(-1);
    const [isLoading, setIsLoading] = useState(true);
    const [skipped, setSkipped] = useState(false);
    const [backCount, SetBackCount] = useState(0);
    const [isUserIP1P, setIsUserIP1P] = useState(false);
    const [continueDisabled, setContinueDisabled] = useState(true);

    useEffect(() => {
        (async () => {

            try {
                if (!isAuthenticated) {
                    // Throw exception if user not authenticated
                    throw new Error("User is not authenticated!");
                }
                await getAccessTokenSilently({ ignoreCache: true });
                const claims = await getIdTokenClaims();
                const ipn = extractIpn(claims!['http://oauth.tmr.qld.gov.au'].acr);

                if (Constants.DEBUG) {
                    console.log('user claims  ipn ->', ipn);
                    console.log('%c[LegalNameSelection] isAuthenticated: %o; user: %o\nipn:%o\nacr:%o\nstate:%o\nglobalState:%o', 'color: #9c6;',
                        isAuthenticated, user, globalState.sessionToken?.ipn, globalState.sessionToken?.acr_values, globalState.idState, globalState);
                }

                saveGlobalState({ sessionToken: JSON.parse(localStorage.getItem('session_token')!) });
                if (globalState.sessionToken.ipn !== "ip1p_nc" && ipn) globalState.sessionToken.ipn = ipn;

                // Get users current IP value and check if IP1P. This is used to decide
                // whether to display the 'provide another document' link currently only
                // and option for users requesting IP1P
                setIsUserIP1P(globalState.sessionToken?.ipn === "ip1p" ? true : false);

                // Check if user should be here
                let mfaAvailable = globalState.userCompletedMFA; 
                if (typeof mfaAvailable === 'undefined' || (user && user.email_verified === false)) {
                    if (Constants.DEBUG) { console.log('%c%s', 'color: #c00;', "Step blocked. Redirecting to uplift"); }
                    navigate("/ip-uplift", { replace: true });
                }
            } catch (error) {
                navigate("/unauthorised", { replace: true });
            }

        })();
    }, []); 

    useEffect(() => {
        // Get legal name options from the API
        const getLegalNameOptions = async () => {
            const token = await getAccessTokenSilently({ ignoreCache: true });
            const idToken = await getIdTokenClaims();

            const response = await getNameOptions(idToken!.sub, token);
            setNameOptions(response.docNames); //Name options returned from the API
        }

        const backCountinSession = window.sessionStorage.getItem("ClaimNameBackCount");
        if (backCountinSession) {
            SetBackCount(+backCountinSession);
        } else {
            window.sessionStorage.setItem("ClaimNameBackCount", '0');
        }

        if(navigatedNameOptions) {
            setNameOptions(navigatedNameOptions);
        } else {
            getLegalNameOptions();
        }
    }, []);

    useEffect(() => {
        // Set selected name options on back from confirmation page / legal first name selection page
        if ((navigatedFullNameIndex ?? -1) > -1) {
            setFullNameOptionIndex(navigatedFullNameIndex);
        }
    }, [])

    useEffect(() => {
        // Save the back count in session storage
        window.sessionStorage.setItem("ClaimNameBackCount", backCount.toString());
    }, [backCount])

    useEffect(() => {
        // Check if there are multiple name options and redirect to the appropriate page
        if (nameOptions && nameOptions.length > 0) {
            if (nameOptions.length > 1) {
                //Multiple family names
                let options = new Map<string, string>();
                nameOptions.map((v: any,i: any) => {
                    options.set(i,v.fullName);
                });
                setNameOptionsList(options);
                setIsLoading(false);
            } else if (nameOptions.length === 1 && nameOptions[0].options) {
                //One family name
                if (nameOptions[0].options.length > 1) {
                    // //Multiple first names
                    navigate("/ip-uplift/legal-first-name-selection", { 
                        replace: true, 
                        state: { 
                            nameOptions: nameOptions, 
                            selectedFullNameRecordIndex: 0,
                            backNavigation: "/ip-uplift/legal-full-name-selection"
                        } 
                    });
                } else if (nameOptions[0].options.length === 1) {
                    //One first name
                    navigate("/ip-uplift/legal-name-confirmation", { 
                        replace: true, 
                        state: { 
                            nameOptions: nameOptions, 
                            selectedFullNameRecordIndex: 0,
                            selectedNameOptionIndex: 0, 
                            backNavigation: "" //No back navigation - this will hide back button and show skip button
                        } 
                    });
                }
            }
        }
    }, [nameOptions]);
  
    useEffect(() => {
        // Scroll to top of the page
      window.scrollTo(scrollToTopOptions);
    }, [])
    
    useEffect(() => {
        // Disable continue button if user click skip
        if (skipped) {
            setContinueDisabled(true);
        }
    }, [skipped])

    useEffect(() => {
        // Enable continue button if user select a name option
        if ((fullnameOptionIndex ?? -1) > -1) {
            setContinueDisabled(false);
            setSkipped(false);
        } else {
            setContinueDisabled(true);
        }
    }, [fullnameOptionIndex])

    const selectAnotherDocument = () => {
        // Set a session storage flag to indicate that the user is adding a document for name claim
        // and navigate to verify your identity page
        window.sessionStorage.setItem('addDocForNameClaim', 'true');
        window.sessionStorage.setItem("ClaimNameBackCount", '0');
        navigate("/ip-uplift/verify-your-identity", { replace: true, state: 
            { ipnLevel: "ip1p", navigateBackTo: "/ip-uplift/legal-full-name-selection" } });
    }

    const skipClicked = () => {
        // Redirect to ReturnToRelyingParty page if user click skip
        if(skipped) {            
            const sessionToken = localStorage.getItem('session_token');
            saveGlobalState({ sessionToken: JSON.parse(sessionToken == null ? "" : sessionToken) });
            navigate("/return-to-relying-party", { replace: true });
        }
        setSkipped(true);
    }

    const continueClicked = () => {
        // Redirect to LegalFirstNameSelection on "continue" button click if there're multiple first name options
        // Redirect to LegalNameConfirmation on "continue" button click if there's only one first name option
        if (nameOptions[fullnameOptionIndex].options.length > 1) {
            navigate("/ip-uplift/legal-first-name-selection", { 
                replace: true, 
                state: { 
                    nameOptions: nameOptions,  
                    selectedFullNameRecordIndex: fullnameOptionIndex,
                    backNavigation: "/ip-uplift/legal-full-name-selection",
                } 
            });
        } else {
            navigate("/ip-uplift/legal-name-confirmation", { 
                replace: true, 
                state: { 
                    nameOptions: nameOptions,  
                    selectedFullNameRecordIndex: fullnameOptionIndex,
                    selectedNameOptionIndex: 0, 
                    backNavigation: "/ip-uplift/legal-full-name-selection",
                } 
            });
        }
    }

    return (
        <>
            {isAuthenticated &&
                <>
                    {isLoading ? 
                        <Loading /> :
                        <>
                            <h1>Confirm your legal name</h1>

                            {window.sessionStorage.getItem('verifiedCredentialInCurrentSession') === 'true' && 
                                <SuccessAlert 
                                    heading="" 
                                    message="You have successfully verified your credential. You will now need to confirm your legal name." 
                                />
                            }

                            <p>
                                We need to confirm your current legal name, as it will be provided 
                                to the agency or service you are accessing.
                            </p>
                            <p>
                                In the next step we will show you how your full name will be saved 
                                in your Queensland Digital Identity.
                            </p>

                            <form className="qg-forms-v2">
                                <ol className="questions">
                                    <li>
                                        <LabelRadio 
                                            id="first-name-options"
                                            label="What is your current legal name?"
                                            inputValue={fullnameOptionIndex}
                                            setInputValue={setFullNameOptionIndex}
                                            options={nameOptionsList}
                                            mandatory={true}
                                        />
                                    </li>
                                </ol>
                            </form>

                            {isUserIP1P &&
                                <InfoAlert message={
                                    <p>
                                        If you want to use another name, other than the ones listed above, you will need 
                                        to <a onClick={selectAnotherDocument} style={{ cursor:"pointer" }}>provide another document</a> that includes your current legal name.
                                    </p>
                                }/>
                            }

                            {skipped && 
                                <WarningAlert
                                    heading="Sure you want to skip?"
                                    message={<>
                                        <p>
                                            if you select 'Skip', the agency or service you are using will be provided with your name 
                                            as it appears on the first document you verified.
                                        </p>
                                        <p>You will be asked to confirm your current legal name the next time you log in to QDI.</p>
                                        <p>Select 'Skip' if you still wish to skip this step.</p>
                                    </>}
                                />
                            }
                    
                            <ol className="questions">
                                <li className="footer">
                                    <ul className="actions">
                                        <PrimaryButton 
                                            id="continueButton" 
                                            heading="Continue" 
                                            action={continueClicked} 
                                            disabled={continueDisabled}
                                        />
                                        <SecondaryButton 
                                            id="skipButton" 
                                            heading="Skip"
                                            action={skipClicked} 
                                        />    
                                    </ul>
                                </li>
                            </ol>
                        </>
                    }
                </>
            }
        </>
    );

}

export default LegalFullNameSelection;