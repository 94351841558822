import { useAuth0 } from '@auth0/auth0-react';
import * as Constants from "../constants";
import RelyingPartyBuilder from '../utils/RelyingPartyBuilder';
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import PrimaryButton from "../components/swe/primary-button";
import useGlobalState from "../hooks/useGlobalState";
import MFAHandler from "../utils/MFAHandler";
import { extractIpn, hasUserReachedOrSurpassedRequestedACR } from "../utils/helpers";
import SuccessAlert from '../components/swe/success-alert';

export const Success = () => {
    const { isAuthenticated, user, getAccessTokenSilently, logout } = useAuth0();
    const { globalState } = useGlobalState();
    const navigate = useNavigate();
    const [accessToken, setAccessToken] = useState('');
    const [auth0State, setAuth0State] = useState('');
    const [sub, setSub] = useState('');
    const [upliftSessionId, setUpliftSessionId] = useState('');

    if (Constants.DEBUG) { console.log("%c[Success] isAuthenticated: %o; user: %o", 'color: #f96;', isAuthenticated, user); }

    useEffect(() => {
        (async () => {

            if (!isAuthenticated) {
                //window.location.href = new RelyingPartyBuilder().withRelyingParty(Constants.AUTH0_UNAUTHORISED_PATH).build();
                navigate("/unauthorised", { replace: true });

            } else {

                // Sometimes the user object is 'undefined' for example if the user has
                // not needed to move through uplift. If so, get the users ipn from the
                // global state
                let usersIpn = user['http://oauth.tmr.qld.gov.au'].ipn;
                if (!usersIpn) usersIpn = globalState.sessionToken.ipn;
                let acrReached = hasUserReachedOrSurpassedRequestedACR(extractIpn(globalState.acr), usersIpn);

                const claimNameRequired = window.sessionStorage.getItem('claimNameRequired') === 'true' ? true : false;

                if (claimNameRequired || !acrReached || (user && user.email_verified === false)) {
                    if (Constants.DEBUG) { console.log('%c%s', 'color: #c00;', "User not completed uplift to requested ACR. Redirecting to uplift"); }
                    navigate("/ip-uplift", { replace: true });
                    return null;
                }

                setAccessToken(await getAccessTokenSilently({ ignoreCache: true }));
                setAuth0State(globalState.idState);
                setSub(globalState.sessionToken.sub);
                setUpliftSessionId(globalState.sessionToken.ip_uplift_session.session_id);

                try {    
                    localStorage.clear();
                    sessionStorage.clear();
                    if (Constants.DEBUG) { console.log('localStorage and sessionStorage cleared!'); }
    
                    const logoutUrl = `${process.env.react_app_auth0_uri}/v2/logout?client_id=${Constants.AUTH0_CLIENTID}&post_logout_redirect_uri=${window.location.origin}`;
                    await fetch(logoutUrl, { mode: 'no-cors', });
                    if (Constants.DEBUG) { console.log('Auth0 QDI logout successful.'); }

                } catch (error) {
                    if (Constants.DEBUG) { console.error('Auth0 QDI logout call failed!'); }
                }
            }
        })();
    }, []);

    async function returnToBroker() {
        // User has reached requested ACR so we return them to the broker/RP
        if (Constants.DEBUG)
            console.log("User successfully complete uplift. Display link to allow user to return to broker/RP");

        try {
            MFAHandler.execute(accessToken, auth0State, sub, upliftSessionId);
        }
        catch (error) {
            if (Constants.DEBUG) { console.log('%c%s', 'color: #c00;', error); }
        }
    }

    return (<>
        {isAuthenticated &&
            <div style={{ margin: "0 auto" }}>

                <h1>Queensland Digital Identity set up complete</h1>

                <SuccessAlert  
                    message={<><p>You have successfully set up your Queensland Digital Identity!</p><p>Select 'Continue' to be redirected to the agency, service or application you were using.</p></>}
                />

                <ol className="questions">
                    <li className="footer">
                        <ul className="actions">
                            <PrimaryButton id="continue" heading="Continue" action={returnToBroker} />
                        </ul>
                    </li>
                </ol>

            </div>
        }
    </>)
};


export default Success;