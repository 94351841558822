import { DEBUG } from "../constants";
import { DLAUser } from "../interfaces/DLAUser";
import { APIJSONResponse, CallIntegrationAPIs, VERBS } from "./api-bridge";
import { AssociateQDIUserAccount, DocumentAlreadyVerifiedError, DocumentVerificationError, UnableToContactIssuingAgencyError } from "./x-api";



/**
 * Get the Document Types to display on Verify Credentials page
 * @param user_id the auth0 user id of this user in the format of auth0|xxxxxxxxxxxxxxx
 * @param ipnLevel Ipn level of the user
 * @param dlaUser Users from DLA Eligibility app, otherwise null
 * @param accessToken Auth0 authentication token used to access APIs
 * @returns the server response if the response is a 200 response.
 */
export async function getDocList(user_id: string, ipnLevel: string, dlaUser: DLAUser, accessToken: string) {

    const removeItem = (arr: string[], itemToRemove: string): string[] => {
        return arr.filter((item) => item !== itemToRemove);
    };

    const injectISDL = (arr: string[]) => {
        let newDocList: string[] = [];
        let docBeforeISDL = arr.includes("PP") ? "PP" : "ML";
        arr.forEach((docType:string) => {
            newDocList.push(docType);
            if(docType === docBeforeISDL) { // Inject IDSL after PP or ML
                newDocList.push("ISDL");
            }
        });
        return newDocList;
    }

    let docsToDisplay = [];

    if (ipnLevel === 'ip1') { // First doc options...

        docsToDisplay.push('DL');
        docsToDisplay.push('PIC');
        docsToDisplay.push('ML');

        if (!dlaUser) {
            docsToDisplay.push('PP');
            docsToDisplay.push('ISDL');
            docsToDisplay.push('VI');
            docsToDisplay.push('IM');
        }
    }

    if (ipnLevel === 'ip1p') { // Second doc options...

        const encodedUserId = encodeURIComponent(user_id);
        const getDocsResponse = await CallIntegrationAPIs(`api/users/identity/DocOptions?user_id=${encodedUserId}`, VERBS.GET, undefined, accessToken);

        if (DEBUG) console.log('DocOptions response, %o', getDocsResponse);
        docsToDisplay = getDocsResponse.body.docOptions;
        if (!dlaUser && docsToDisplay.includes('DL')) {
            docsToDisplay = injectISDL(docsToDisplay); // Inject ISDL at the correct possition
        }

        if (dlaUser) {
            if (DEBUG) console.log('Filtering docs for ip1p DLA user');

            if (docsToDisplay.includes('DL')) {
                // IF DL in the list of available docs, make this the only option
                docsToDisplay = [];
                docsToDisplay.push('DL');
                docsToDisplay.push('PIC');
                docsToDisplay.push('ML');

            } else {
                // TMR product already used, filter Medicare Card from remaining available options
                docsToDisplay = removeItem(docsToDisplay, 'MD');
            }
        }
    }

    if (ipnLevel === 'ip2' && dlaUser) { // DLA users only when third doc required

        if (dlaUser.result === 'TMR document required') {
            if (DEBUG) console.log('DLA user requires TMR document');
            docsToDisplay.push('DL');
            docsToDisplay.push('PIC');
            docsToDisplay.push('ML');
        }

        if (dlaUser.result === 'Third document required') {
            if (DEBUG) console.log('DLA user requires third document');

            // Third doc required means they've already used a TMR product. Medicare Card not an option.

            // Remove second doc as an option if known
            // UPDATE: No, currently no scenario where this is needed based on current rules.
            // if (dlaUser.secondDoc !== 'PP') docsToDisplay.push('PP');
            // if (dlaUser.secondDoc !== 'VI') docsToDisplay.push('VI');
            // if (dlaUser.secondDoc !== 'CC') docsToDisplay.push('CC');
            // if (dlaUser.secondDoc !== 'IM') docsToDisplay.push('IM');
            // if (dlaUser.secondDoc !== 'BC') docsToDisplay.push('BC');
            docsToDisplay.push('PP');
            docsToDisplay.push('VI');
            docsToDisplay.push('CC');
            docsToDisplay.push('IM');
            docsToDisplay.push('BC');
        }
    }

    if (DEBUG) console.log('Returning doc options %o', docsToDisplay);
    return { status: 200, docList: docsToDisplay };
}

/**
 * API that verify's the entered details of an interstate driver's licence.
 *
 * @param licenceDetails an AssociateQDIUserAccount object containing the driver's licence details in an AssociateQDIUserAccount instance.
 * @param token the IDP JWT
 * @throws DocumentAlreadyVerifiedError when http status code is 409
 * @throws DocumentVerificationError when http status code is 404
 * @throws Error("Unhandled response") if not above conditions or success occurring.
 * @returns true if succesfull
 */
export async function verifyInterstateDriversLicence(stateName: string, licenceDetails: AssociateQDIUserAccount, token: string) {
    const returned = await CallIntegrationAPIs('api/users/identity/dl/' + stateName, VERBS.POST, licenceDetails, token);

    return handleIntegrationAPIResponse(returned);
}

/**
 * Get all possible permutations of first and middle name options for given user.
 * 
 * @param user_id - The auth0 user id of this user in the format of auth0|xxxxxxxxxxxxxxx.
 * @param accessToken - Auth0 authentication token used to access APIs.
 * @returns The server response if the response is a 200 response. If not a 200 response, throws an Error.
 */
export async function getNameOptions(user_id: string, accessToken: string) {

    try {
        const encodedUserId = encodeURIComponent(user_id);
        const response = await CallIntegrationAPIs(`api/users/identity/name-options?user_id=${encodedUserId}`, VERBS.GET, undefined, accessToken);

        if (response.code === 200) {
            return response.body;
        } else {
            throw new Error("Unhandled response.");
        }
    } catch (error) {
        console.log(error);
        throw new Error("Unhandled response.");
    }
}

/**
 * Confirm the selected name option for the user.
 * 
 * @param user_id - The auth0 user id of this user in the format of auth0|xxxxxxxxxxxxxxx.
 * @param nameOptions - The selected name option for the user.
 * @param accessToken - Auth0 authentication token used to access APIs.
 * @returns The server response if the response is a 200 response. If not a 200 response, throws an Error.
 */
export async function confirmSelectedNameOption(user_id: string, nameOptions: AssociateQDIUserAccount, accessToken: string) {
    const encodedUserId = encodeURIComponent(user_id);
    try {
        const response = await CallIntegrationAPIs(`api/users/identity/name-confirmation?user_id=${encodedUserId}`, VERBS.PATCH, nameOptions, accessToken);
        console.log(response);

        if (response.code === 200) {
            return response.body;
        } else {
            throw new Error("Unhandled response.");
        }
    } catch (error) {
        console.log(error);
        throw new Error("Unhandled response.");
    }
}

/**
 * If not a 200 response, throws an Error subclass.
 *
 * @param response APIJSONResponse instance from api-bridge call.
 * @returns  true if succesfull
 */
function handleIntegrationAPIResponse(response: APIJSONResponse) {
    if (response.code === 200) {
        // Set flag to indicate user must now select a preferred name before uplift complete
        window.sessionStorage.setItem('claimNameRequired', 'true');
        // Set flag to indicate that user has successfully verified a document in current session
        window.sessionStorage.setItem('verifiedCredentialInCurrentSession', 'true');
        return true;
    }
    else if (response.code === 409) {
        throw new DocumentAlreadyVerifiedError();
    }
    else if (response.code === 404 || response.code === 400) {
        throw new DocumentVerificationError(response.body.Indicator,
            response.body.Message);
    }
    else if (response.code === 500 && response.body) {
        if (response.body.Message === "Unable to contact issuing authority") {
            throw new UnableToContactIssuingAgencyError();
        }
    }
    throw new Error("Unhandled response.");
}