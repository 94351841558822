import * as Constants from "../constants";
import { v4 as uuidv4 } from 'uuid';
import { AssociateQDIUserAccount, XBody } from "./x-api";

/**
 * De-protocoled API JSON response value object. 
 * 
 * @param body the JSON object of the API response
 * @param code the http status code returned by the API
 */
export class APIJSONResponse {
    body : any;
    code: number;

    constructor(body:any, code:number) {
        this.body = body;
        this.code = code;
    }
}

/**
 * Generic function that makes a http protocol call against Mulesoft APIs and returns the JSON response. Caught errors throw a standard Error.
 * 
 * @param api uri of the api sans host
 * @param method GET POST HEAD OPTIONS PUT DELETE
 * @param body json payload
 * @param accessToken a JWT that has rights to call the APIs
 * @param allowedResponses optional array of http status codes API is allowed to return as not an error
 * @throws Error if allowedResponses is not meet or on catch(error)
 * 
 * @returns an API wrapper object
 */
const Call = async (api: string, method: string, body: any, accessToken: string, allowedResponses?: Array<number>) => {
    try {
        let uri = Constants.API_URI + api;

        // TODO: ideally this should be passed as an arg to the function, furture release
        const correlationId = window.sessionStorage.getItem('correlation-id');

        const response = await fetch(uri, {
            method: method,
            headers: {
                // Note, as per Manual, we're keeping it as x-correlation-id due to workload required to change
                'x-correlation-id' : correlationId!,
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'eip_client_id': `${Constants.EIP_CLIENT_ID}`,
                Authorization: `Bearer ${accessToken}`
            },
            body: (body) ? JSON.stringify(body, (key, value) => {
                if (value !== null && (typeof value =='string'? value.length > 0: true)) return value
              }): null
        });

        let returns = '';
        if(response.status !== 204) {
            returns = await response.json();
        }

        if (allowedResponses && !allowedResponses.includes(response.status)) {
            console.error(returns);
            throw new Error ("API call " + api + " failed with code " + response.status);
        }
        
        return new APIJSONResponse(returns, response.status);
    } catch(error) {
        console.error(error);

        throw new Error("API call " + api + " failed with error.");
    }
}

/**
 * Generic function that makes a http protocol call against AWS APIGateway based APIs and returns the JSON response. Caught errors throw a standard Error.
 * @param api API endpoint name
 * @param method HTTP request method
 * @param body Request payload
 * @param accessToken a JWT that has rights to call the APIs
 * @throws Error if allowedResponses is not meet or on catch(error)
 * @returns APIJSONResponse response object
 */
const CallAPIGateway = async (api: string, method: string, body: any, accessToken: string) => {
    try {
        let uri = Constants.APIGATEWAY_URI + api;

        const response = await fetch(uri, {
            method: method,
            headers: {
                // Note this function only used to check service availability, no correlation-id passed
                "X-Request-Id": uuidv4(),
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`
            },
            body: (body) ? JSON.stringify(body, (key, value) => {
                if (value !== null && (typeof value =='string'? value.length > 0: true)) return value
              }): null
        });

        let returns = '';
        if(response.status !== 204) {
            returns = await response.json();
        }
        
        return new APIJSONResponse(returns, response.status);
        
    } catch (error) {
        console.log(error);
        throw new Error("APIGateway call " + api+ " failed with error");
    }
}

const CallIntegrationAPIs = async (api: string, method: string, body: AssociateQDIUserAccount | undefined, accessToken: string) => {
    try {
        let uri = Constants.INTEGRATION_API_URI + api;

        // TODO: ideally this should be passed as an arg to the function, furture release
        // Note, in this request we also keep a X-Request-Id for debug and backward compatability
        const correlationId = window.sessionStorage.getItem('correlation-id');

        const response = await fetch(uri, {
            method: method,
            headers: {
                'correlation-id': correlationId!,
                'X-Request-Id': uuidv4(),
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`
            },
            body: (body) ? JSON.stringify(body, (key, value) => {
                if (value !== null && (typeof value =='string'? value.length > 0: true)) return value
            }): null
        });

        let returns = '';
        if(response.status !== 204) {
            returns = await response.json();
        }
        
        return new APIJSONResponse(returns, response.status);
    } catch (error) {
        console.log(error);
        throw new Error("Call to " + api+ " integration API failed with error");
    }
}

export const VERBS = {
    GET: 'GET',
    POST: 'POST',
    DELETE: 'DELETE',
    PATCH: 'PATCH'
};

export {Call, CallAPIGateway, CallIntegrationAPIs}