import { useEffect, useRef } from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import * as Constants from "../constants"

import CriticalAlert from '../components/swe/critical-alert';
import { scrollToTopOptions } from '../utils/helpers';

export const LegalNameTooManyAttempts = () => {

    const { logout } = useAuth0();
    const alertRef = useRef(null);

    useEffect(() => {
        window.scrollTo(scrollToTopOptions);
    }, []);

    useEffect(() => {
        (async () => {
            if (Constants.DEBUG) { console.log('Hard stop! Uplift process terminated. Logging user out of QDI session.'); }

            logout({
                localOnly: true,
            });

            localStorage.clear();
            sessionStorage.clear();
        })();
    }, []);

    return (
        <>
            <div>
                <h1>Confirm your legal name</h1>
                <CriticalAlert
                    heading="Too many attempts"
                    message={<>
                        <p>We were unable to successfully confirm your legal name.</p>
                        <p>In order to continue, you will need to return to the agency, service or application that you were attempting to use.</p>
                    </>}
                />
            </div>
        </>
    )
};

export default LegalNameTooManyAttempts;