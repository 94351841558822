import { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import InfoAlert from "../components/swe/info-alert";
import PrimaryButton from "../components/swe/primary-button";
import * as Constants from "../constants";
import MFAHandler from "../utils/MFAHandler";
import useGlobalState from "../hooks/useGlobalState";
import { extractIpn, hasUserReachedOrSurpassedRequestedACR, scrollToTopOptions } from "../utils/helpers";
import { useNavigate } from "react-router-dom";
import Loading from "../components/Loading";


export const ReturnToRelyingParty = () => {
    const { isAuthenticated, user, getAccessTokenSilently, logout } = useAuth0();
    const { globalState } = useGlobalState();
    const navigate = useNavigate();

    const [accessToken, setAccessToken] = useState('');
    const [auth0State, setAuth0State] = useState('');
    const [sub, setSub] = useState('');
    const [upliftSessionId, setUpliftSessionId] = useState('');
    const [isLoading, setIsLoading] = useState(true);

    if (Constants.DEBUG) { console.log("%c[ReturnToRelyingParty] isAuthenticated: %o; user: %o", 'color: #f96;', isAuthenticated, user); }
  
    useEffect(() => {
        (async () => {
            if (!isAuthenticated) {
                navigate("/unauthorised", { replace: true });

            } else {

                // Sometimes the user object is 'undefined' for example if the user has
                // not needed to move through uplift. If so, get the users ipn from the
                // global state
                let usersIpn = user!['http://oauth.tmr.qld.gov.au'].ipn;
                if (!usersIpn) usersIpn = globalState.sessionToken.ipn;
                let acrReached = hasUserReachedOrSurpassedRequestedACR(extractIpn(globalState.acr)!, usersIpn);
                console.log("gsAcr: %o; userIpn: %o; acrReached: %o",'color: #f96;', globalState.acr, usersIpn, acrReached);

                if (!acrReached || (user && user.email_verified === false)) { //TODO Check this
                    if (Constants.DEBUG) { console.log('%c%s', 'color: #c00;', "User not completed uplift to requested ACR. Redirecting to uplift"); }
                    navigate("/ip-uplift", { replace: true });
                    return null;
                }

                setAccessToken(await getAccessTokenSilently({ ignoreCache: true }));
                setAuth0State(globalState.idState);
                setSub(globalState.sessionToken.sub);
                setUpliftSessionId(globalState.sessionToken.ip_uplift_session.session_id);

                localStorage.clear();
                sessionStorage.clear();
                if (Constants.DEBUG) { console.log('session and localstorage cleared, calling logout url...'); }

                try {
                    // const logoutUrl = `${process.env.react_app_auth0_uri}/v2/logout?client_id=${Constants.AUTH0_CLIENTID}&post_logout_redirect_uri=${window.location.origin}`;
                    // await fetch(logoutUrl, { mode: 'no-cors', });                
                    logout({
                        localOnly: true,
                    });
                    if (Constants.DEBUG) { console.log('Logout successful.'); }
                } catch (error) {
                    if (Constants.DEBUG) { console.error('logout call failed!'); }
                } finally {
                    setIsLoading(false);
                }
            }
        })();
    }, []);

    useEffect(() => {
      window.scrollTo(scrollToTopOptions);
    }, []);
    

    async function returnToBroker() {
        // User has reached requested ACR so we return them to the broker/RP
        setIsLoading(true);
        if (Constants.DEBUG)
            console.log("User successfully complete uplift. Display link to allow user to return to broker/RP");

        try {
            MFAHandler.execute(accessToken, globalState.idState, globalState.sessionToken.sub, globalState.sessionToken.ip_uplift_session.session_id);
        }
        catch (error) {
            if (Constants.DEBUG) { console.log('%c%s', 'color: #c00;', error); }
        }
    }

    return (
        <>
            {isLoading ?
                <Loading /> :
                <div>

                    <h1>Return to agency or service you were using</h1>

                    <InfoAlert  
                        message={<>
                            <p>
                                When you return to the agency or service you were using, we will provide them with your name 
                                as it appears on the first document you verified in QDI.
                            </p>
                            <p>
                                You will still be asked to confirm your current legal name the next time you login with QDI.
                            </p>
                            <p>
                                Select 'Continue' to be redirected to the agency, service or application you were using.
                            </p>
                        </>}
                    />

                    <ol className="questions">
                        <li className="footer">
                            <ul className="actions">
                                <PrimaryButton id="continue" heading="Continue" action={returnToBroker} />
                            </ul>
                        </li>
                    </ol>

                </div>
            }
        </>
    );
}

export default ReturnToRelyingParty;